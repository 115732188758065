import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import fetchWithToken from "../fetchers/fetch-with-token";
import { Country } from "../../models/Dtos";

export function useCountries() {
  const { getAccessTokenSilently } = useAuth0();

  const { data, mutate, error } = useSWR<Country[]>([`${process.env.REACT_APP_API_BASE_URL}/regions/countries`, getAccessTokenSilently], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    loading,
    loggedOut,
    data,
    mutate,
  };
}