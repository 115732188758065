import { useEffect, useState } from "react";
import { Input, Form, DatePicker, Select, Popconfirm, Button, message } from "antd";
import { Modal } from 'antd';
import { HeliumLevelFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";

interface FormHeliumLevelProps {
  onSubmit: (values: HeliumLevelFormValues) => Promise<AxiosResponse<any, any>>;
  onCancel: () => void;
  onClear: (values: HeliumLevelFormValues) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: HeliumLevelFormValues;
}

const FormHeliumLevel = ({
  visible,
  onSubmit,
  onCancel,
  onClear,
  onClose = () => { },
  isNew = true,
  initialValues = {},
}: FormHeliumLevelProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const onFinish = async (values: HeliumLevelFormValues) => {
    setIsSubmitting(true);
    try {
      await onSubmit(values);
      message.success('created');
      onClose();
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  const handleClear = async () => {
    setIsClearing(true);
    try {
      await onClear(initialValues);
      message.success('cleared');
      onClose();
    } catch (e) {
      console.log(e);
      message.error('Clear failed');
    } finally {
      setIsClearing(false);
    }
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  const suffixSelector = (
    <Form.Item name="unit" noStyle>
      <Select style={{ width: 60 }}>
        <Select.Option value="Liter">L</Select.Option>
        <Select.Option value="Percent">%</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Modal
      forceRender
      visible={visible}
      title={isNew ? `Helium level` : `Edit`}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Popconfirm
          title="Are you sure to clear level data?"
          onConfirm={handleClear}
          okText="Yes"
          cancelText="No"
        >
          <Button
            key="clear"
            disabled={isSubmitting}
            loading={isClearing}
            danger
          >
            Clear
          </Button>
        </Popconfirm>,
        <Button
          key="save"
          type="primary"
          disabled={isClearing}
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="heliumLevel"
        initialValues={{
          unit: 'Percent'
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="levelId"
          label="Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="date"
          label="Date of measure"
          rules={[
            { required: true, message: 'Please select date of measure.' }
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Level"
          rules={[
            { required: true, message: 'Please fill level.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('unit') === 'Percent' && value > 100) {
                  return Promise.reject('Should\'t larger than 100 when using unit "%"!');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input addonAfter={suffixSelector} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormHeliumLevel;
