import { Button } from 'antd';
import { Region } from '../../../models/Entities';
import List from './List';
import { RegionFormValues, RegionQueryParams } from '../../../models/Dtos';
import styled from 'styled-components';
import { useEntityPage } from '../../../lib/hooks/useEntityPage';
import FormRegion from './Form';

const TableOperations = styled.div`
  margin-bottom: 16px;
  & > button {
    margin-right: 8px;
  }
`;

function RegionPage() {
  const {
    isNew,
    loading,
    data: paginatedRegions,
    isFormVisible,
    initialValues,
    handleAdd,
    handleEdit,
    handleFormClose,
    queryParams,
    createEntity: createRegion,
    updateEntity: updateRegion,
    handleFilterChange,
  } = useEntityPage<Region, RegionFormValues, RegionQueryParams>('regions', { pageIndex: 1, pageSize: 20 });

  return (
    <>
      <TableOperations>
        <Button type="primary" onClick={handleAdd} >
          Add Region
        </Button>
      </TableOperations>
      <List
        paginatedRegions={paginatedRegions}
        isLoading={loading}
        handleEditRegion={handleEdit}
        regionQueryParams={queryParams}
        onFilterChange={handleFilterChange}
      />
      <FormRegion
        visible={isFormVisible}
        onClose={handleFormClose}
        isNew={isNew}
        initialValues={initialValues}
        onCreate={createRegion}
        onUpdate={updateRegion}
      />
    </>
  );
}

export default RegionPage;

