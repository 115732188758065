import { useEffect, useState } from "react";
import { Modal, Form, Select, Button, message } from "antd";
import { useAllRegions } from "../../../lib/hooks/use-all-regions";
import { RegionPermissionFormValues } from "../../../models/Dtos";
import { useRoles } from "../../../lib/hooks/use-roles";

interface FormRegionPermissionProps {
  onUpdateRegionPermission: any;
  onClose: () => void;
  visible: boolean;
  initialValues: RegionPermissionFormValues;
}

const FormRegionPermission = ({
  onUpdateRegionPermission,
  onClose = () => { },
  visible = false,
  initialValues,
}: FormRegionPermissionProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: allRegions } = useAllRegions();
  const { data: allRoles } = useRoles();
    
  const onFinish = async (values: RegionPermissionFormValues) => {
    setIsSubmitting(true);
    try {
      await onUpdateRegionPermission(values);
      message.success('Permission updated.');
      onClose();
    } catch (e) {
      console.log(e);
      message.error('Permission update failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {}

  const handleOk = () => {
    form.submit();
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  return (
    <Modal
      forceRender
      maskClosable={false}
      visible={visible}
      title={`Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="editRegion"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="regionId"
          label="Region"
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            options={allRegions ? allRegions.map(region => ({label: region.name, value: region.regionId})) : []}
            optionFilterProp="label"
          >
          </Select>
        </Form.Item>

        <Form.Item
          name="roles"
          label="Roles"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
          >
            {allRoles && allRoles.map(role => {
              return (
                <Select.Option key={role.key}>{role.value}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormRegionPermission;
