import { useAuth0 } from "@auth0/auth0-react";
import { TableRowSelection } from "antd/es/table/interface";
import axios from "axios";
import { Key, useState } from "react";
import { UsersRegionPermissionPayload } from "../../../models/Dtos";
import { User } from "../../../models/Entities";

export function useRegionPermission() {
  const { getAccessTokenSilently } = useAuth0();
  const [formRegionPermissionModalVisible, setFormRegionPermissionModalVisible] = useState(false);
  const [formRegionPermissionInitialValues, setFormRegionPermissionInitialValues] = useState({});
  const [isFormRegionPermissionSubmitting, setIsFormRegionPermissionSubmitting] = useState(false);

  const editRegionPermission = () => {
    setFormRegionPermissionInitialValues({})
    setFormRegionPermissionModalVisible(true);
  }

  const handleRegionPermissionModalClose = () => {
    setFormRegionPermissionModalVisible(false);
    // mutate();
  }

  const [selectedUserIds, setSelectedUserIds] = useState<Key[]>([]);
  const onSelectChange = (selectedRowKeys: Key[], selectedRows: User[]) => {
    console.log('selecteUserIds changed: ', selectedRowKeys);
    setSelectedUserIds(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<User> = {
    selectedRowKeys: selectedUserIds,
    onChange: onSelectChange,
  };
  const hasSelected = selectedUserIds.length > 0;

  const updateUsersRegionPermission = async (payload: UsersRegionPermissionPayload) => {
    const token = await getAccessTokenSilently();
    return await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/regions`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );
  }

  const handleUpdateRegionPermission = async (values: any) => {
    // setIsFormRegionPermissionSubmitting(true);
    return updateUsersRegionPermission({
      userIds: selectedUserIds,
      regionId: values.regionId,
      roles: values.roles
    });

  }

  return {
    formRegionPermissionModalVisible,
    formRegionPermissionInitialValues,
    isFormRegionPermissionSubmitting,
    handleRegionPermissionModalClose,
    editRegionPermission,
    rowSelection,
    hasSelected,
    handleUpdateRegionPermission,
  }
}