import { useEffect, useState } from "react";
import { Modal, Input, Form, Button, message, Select } from "antd";
import { RegionFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";
import { Region } from "../../../models/Entities";
import { useCountries } from "../../../lib/hooks/use-countries";

interface FormRegionProps {
  onCreate: (values: RegionFormValues) => Promise<AxiosResponse<Region, any>>;
  onUpdate: (values: RegionFormValues, entityId: string) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: RegionFormValues;
}

const FormRegion = ({
  onCreate,
  onUpdate,
  onClose,
  visible = false,
  isNew = true,
  initialValues,
}: FormRegionProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: countries } = useCountries();
    
  const onFinish = async (values: RegionFormValues) => {
    setIsSubmitting(true);
    try {
      if (isNew === true) {
        await onCreate(values);
        message.success('created');
        onClose();
      } else {
        await onUpdate(values, values.regionId!);
        message.success('updated');
        onClose();
      }
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  return (
    <Modal
      forceRender
      maskClosable={false}
      visible={visible}
      title={isNew ? `Create tube type` : `Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="formRegion"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="regionId"
          label="Region Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please fill in the name' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="countries"
          label="Countries"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            options={countries ? countries.map(country => ({label: country.englishName, value: country.twoLetterISORegionName})) : []}
            optionFilterProp="label"
          >
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormRegion;
