import { Button } from 'antd';
import { User } from '../../../models/Entities';
import { UserFormValues, UserQueryParams } from '../../../models/Dtos';
import FormRegionPermission from './FormRegionPermission';
import styled from 'styled-components';
import { useEntityPage } from '../../../lib/hooks/useEntityPage';
import List from './List';
import { useRegionPermission } from './useRegionPermission';

const TableOperations = styled.div`
  margin-bottom: 16px;
  & > button {
    margin-right: 8px;
  }
`;

function UserPage() {
  const {
    loading,
    data: paginatedItems,
    handleEdit,
    queryParams,
    handleFilterChange,
  } = useEntityPage<User, UserFormValues, UserQueryParams>('users', { pageIndex: 1, pageSize: 20 });

  const {
    formRegionPermissionModalVisible,
    formRegionPermissionInitialValues,
    isFormRegionPermissionSubmitting,
    handleRegionPermissionModalClose,
    editRegionPermission,
    rowSelection,
    hasSelected,
    handleUpdateRegionPermission,
  } = useRegionPermission();
    // setIsFormRegionPermissionSubmitting(false);
    // if (status === 200) {
    //   message.success("updated");
    //   setFormRegionPermissionModalVisible(false);
    //   dispatch(fetchUserList(filter));
    // } else {
    //   message.error("Failed to update");
    // }
  return (
    <>
      <TableOperations>
        <Button type="primary" onClick={editRegionPermission} disabled={!hasSelected} loading={false}>
          Permission
        </Button>
      </TableOperations>
      <List
        paginatedItems={paginatedItems}
        isLoading={loading}
        handleEdit={handleEdit}
        regionQueryParams={queryParams}
        onFilterChange={handleFilterChange}
        rowSelection={rowSelection}
      />

      {/* <FormUser
        visible={formUserModalVisible}
        onClose={handleUserModalClose}
        isNew={isUserFormNew}
        initialValues={formUserInitialValues}
        onUpdateUser={updateUser}
      /> */}
      <FormRegionPermission
        onUpdateRegionPermission={handleUpdateRegionPermission}
        visible={formRegionPermissionModalVisible}
        onClose={handleRegionPermissionModalClose}
        initialValues={formRegionPermissionInitialValues}
      />
    </>
  );
}

export default UserPage;
