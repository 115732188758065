import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { UserInfo } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useUser() {
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data, mutate, error } = useSWR<UserInfo>([`${process.env.REACT_APP_API_BASE_URL}/user`, getToken], fetchWithToken);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    loading,
    loggedOut,
    user: data,
    mutate,
  };
}