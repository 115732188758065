import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSWR from "swr";
import { HeliumLevelFormValues } from "../../models/Dtos";
import { MrSystem } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useMrSystem(serialNumber?: string) {
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, mutate } = useSWR<MrSystem>(
    serialNumber ? [`${process.env.REACT_APP_API_BASE_URL}/mr-systems/${serialNumber}`, getAccessTokenSilently] : null,
    fetchWithToken,
    {
      revalidateOnFocus: false,
      revalidateOnMount:true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0
    }
  );

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const updateLevel = async (level: HeliumLevelFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/levels/${level.levelId}`, level, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const clearLevel = async (level: HeliumLevelFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/levels/${level.levelId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  // const updateDesign = async (design: DesignFormValues) => {
  //   const token = await getAccessTokenSilently();
  //   return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/designs/${design.id}`, design, {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`,
  //     },
  //   });
  // }

  return {
    loading,
    loggedOut,
    mrSystem: data,
    mutate,
    updateLevel,
    clearLevel,
  };
}