import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import fetchWithToken from "../fetchers/fetch-with-token";
import QueryString from "qs";
import axios from "axios";
import { PaginatedItems } from "../../models/Dtos";

export function useEntities<EntityType, EntityFormValuesType>(
  apiPath: string,
  queryParams: any
) {
  const { getAccessTokenSilently } = useAuth0();

  const queryString = QueryString.stringify(queryParams);

  const { data, mutate, error } = useSWR<PaginatedItems<EntityType>>(
    [
      `${process.env.REACT_APP_API_BASE_URL}/${apiPath}?${queryString}`,
      getAccessTokenSilently,
    ],
    fetchWithToken
  );

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const createEntity = async (values: EntityFormValuesType) => {
    const token = await getAccessTokenSilently();

    return await axios.post<EntityType>(
      `${process.env.REACT_APP_API_BASE_URL}/${apiPath}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const updateEntity = async (
    values: EntityFormValuesType,
    entityId: string
  ) => {
    const token = await getAccessTokenSilently();
    return await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/${apiPath}/${entityId}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return {
    loading,
    loggedOut,
    data,
    mutate,
    createEntity,
    updateEntity,
  };
}
