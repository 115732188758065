import { Button, Table, TablePaginationConfig, Tag } from 'antd';
import { Region } from '../../../models/Entities';
import { ColumnsType } from 'antd/es/table';
import { PaginatedItems, RegionQueryParams } from '../../../models/Dtos';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import { useTableFilter } from '../../../lib/hooks/use-table-filter';

interface RegionListProps {
  paginatedRegions?: PaginatedItems<Region>,
  isLoading: boolean,
  handleEditRegion: (vibrationDecoupler: Region) => void,
  regionQueryParams: RegionQueryParams,
  onFilterChange: (value: RegionQueryParams) => void,
}

function List({
  paginatedRegions,
  isLoading,
  handleEditRegion,
  regionQueryParams,
  onFilterChange,
}: RegionListProps) {

  const { getColumnSearchProps } = useTableFilter();
  
  const pagination: TablePaginationConfig | undefined = paginatedRegions ? {
    pageSize: paginatedRegions.pageSize,
    current: paginatedRegions.pageIndex,
    total: paginatedRegions.count,
    onChange: ((page, pageSize) => {
      onFilterChange({ ...regionQueryParams, pageIndex: page, pageSize });
    }),
  } : undefined;
  
  const columns: ColumnsType<Region> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name', 'name'),
    },
    {
      title: 'Countries',
      dataIndex: 'countries',
      render: (countries: string[]) => (
        <>
          {countries.map(country => (
            <Tag color="blue" key={country}>
              {country}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) =>
        <Button
          size="small"
          onClick={() =>
            handleEditRegion(record)}
        >
          Edit
        </Button>,
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Region> | SorterResult<Region>[],
    extra: TableCurrentDataSource<Region>
  ) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }
    onFilterChange(params);
  }

  return (
    <Table<Region>
      columns={columns}
      rowKey={record => record.regionId}
      dataSource={paginatedRegions?.items}
      loading={isLoading}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
}

export default List;

