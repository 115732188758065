export interface Role {
  id: number;
  name: string;
}

export interface UserInfo {
  id: string;
  avatar: string;
  email: string;
  emailVerified: boolean;
  familyName: string;
  gid: string;
  givenName: string;
  profileUpdatedAt: Date;
  roles: string[];
}

export interface Region {
  regionId: string,
  name: string,
  countries?: string[],
}

export interface RegionRole {
  regionId: string,
  regionName: string,
  roles: string[],
}

export interface User {
  userId: string,
  username: string,
  name: string,
  email: string,
  department: string | null,
  provider: string,
  roles: string[],
  regionRoles: RegionRole[],
}

export interface Level {
  levelId: string;
  mrSystemId: string;
  amount?: number;
  levelType: string;
  levelMeasureDate: string;
  unit: string;
  sortOrder: number;
}

export interface MrSystem {
  mrSystemId: string;
  type: string;
  country: string;
  site: string;
  factory: string;
  shipment?: string;
  levels: Level[];
}

export interface Refilling {
  refillingId: string;
  mrSystemId: string;
  amount: number;
  reason: string;
  notes?: string;
  refilledAt: string;
}

export const RefillingReason: {[key: string]: string} = {
  'BlockedInTheCustomerSite': 'Blocked in the customs',
  'LossDuringTransportation': 'Loss during transportation',
  'SiteIsNotReadyForTheMagnet': 'Site is not ready for the magnet',
  'UnexpectedPowerDown': 'Unexpected power down',
  'Quench': 'Quench(s)',
  'MoreThanRecommended': 'Additional helium required than recommended',
  'Other': 'Other',
};

export const ReasonColorMap: {[key: string]: string} = {
  'Blocked in the customs': '#6395f9',
  'Loss during transportation': '#62daab',
  'Site is not ready for the magnet': '#657798',
  'Unexpected power down': '#f6c022',
  'Quench(s)': '#e96c5b',
  'MoreThanRecommended': '#e91c5b',
  'Other': '#74cbed',
};

export interface SpaSettings {
  healthineersIdParentDomain?: string,
  healthineersIdDomain?: string,
  healthineersIdClientId?: string,
  healthineersIdRedirectUri?: string,
  healthineersIdAudience?: string,
}