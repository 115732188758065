import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSWR from "swr";
import { RefillingFormValues } from "../../models/Dtos";
import { Refilling } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useMrSystemRefillings(serialNumber?: string) {
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, mutate } = useSWR<Refilling[]>(
    serialNumber ? [`${process.env.REACT_APP_API_BASE_URL}/mr-systems/${serialNumber}/refillings`, getAccessTokenSilently] : null,
    fetchWithToken,
    {
      revalidateOnFocus: false,
      revalidateOnMount:true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0
    }
  );

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const createRefilling = async (refilling: RefillingFormValues) => {
    const token = await getAccessTokenSilently();

    return await axios.post<Refilling>(`${process.env.REACT_APP_API_BASE_URL}/refillings`, refilling, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const updateRefilling = async (refilling: RefillingFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/refillings/${refilling.refillingId}`, refilling, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  const deleteRefilling = async (refilling: RefillingFormValues) => {
    const token = await getAccessTokenSilently();
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/refillings/${refilling.refillingId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  return {
    loading,
    loggedOut,
    refillings: data,
    mutate,
    createRefilling,
    updateRefilling,
    deleteRefilling,
  };
}