import React from "react";
import { useHistory } from "react-router-dom";
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import { useSpaSettings } from "../lib/hooks/useSpaSettings";
import { BlockLoading } from "../components/Loading";

const Auth0ProviderWithHistory = (props: React.PropsWithChildren<{}>) => {
  const { data, loading } = useSpaSettings();
  const history = useHistory();

  const audience =
    data?.healthineersIdAudience &&
    data?.healthineersIdAudience !== "YOUR_API_IDENTIFIER"
      ? data?.healthineersIdAudience
      : null;

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || "/");
  };

  const providerConfig: Auth0ProviderOptions = {
    domain: data?.healthineersIdDomain ? data?.healthineersIdDomain : "",
    clientId: data?.healthineersIdClientId ? data?.healthineersIdClientId : "",
    ...(audience ? { audience: audience } : null),
    redirectUri: data?.healthineersIdRedirectUri
      ? data?.healthineersIdRedirectUri
      : "",
    onRedirectCallback,
    useRefreshTokens: true,
  };

  if (loading) return <BlockLoading />;

  return <Auth0Provider {...providerConfig}>{props.children}</Auth0Provider>;
};

export default Auth0ProviderWithHistory;
