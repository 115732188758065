import { Button, Spin } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomeHeader = styled.h1`
  padding: 36px 0 16px;
  font-size: 24px;
  text-align: center;
`;

const Homebody = styled.div`
  padding: 16px;
  min-height: 380px;
  text-align: center;
`;

function HomePage() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  return (
    <>
      <HomeHeader>Welcome to Helium Tracker!</HomeHeader>
      <Homebody>
        {isLoading &&
          <Spin />
        }
        {!isAuthenticated && !isLoading &&
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              size="large"
              shape='round'
              onClick={() => loginWithRedirect()}>
              Login
            </Button>
          </div>
        }
        {isAuthenticated &&
          <div>
            <Link to={'/systems/search'}>Find MR System</Link>.
          </div>
        }
      </Homebody>
    </>
  );
}

export default HomePage;
