import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr"
import { Region } from "../../models/Entities";
import fetchWithToken from "../fetchers/fetch-with-token";

export function useAllRegions() {
  const { getAccessTokenSilently } = useAuth0();
  const { data, error } = useSWR<Region[]>([`${process.env.REACT_APP_API_BASE_URL}/regions/all`, getAccessTokenSilently], fetchWithToken)

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    data,
    loading,
    loggedOut,
  }
}
