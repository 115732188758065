import { Input, Row, Col, Card, Form, Button } from "antd";
import { useHistory } from "react-router-dom";
import { SearchData } from "../../../models/Dtos";

const SearchPage = () => {

  const history = useHistory();

  const [form] = Form.useForm();
  const onFinish = (values: SearchData) => {
    history.push(`/systems/${values.serialNumber}`);
  }

  const onFinishFailed = () => {

  }

  const error = null;

  return (
    <>
      <Row>
        <Col xs={{ span: 24 }}>
          <Card
          >
            <Form
              name="system-search"
              initialValues={{}}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Form.Item
                name="serialNumber"
                rules={[
                  { required: true, message: 'Please fill Serial Number' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="System Serial Number"
                />
              </Form.Item>
              <Form.Item help={error ? 'Search Failed.' : ''}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  Search
              </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SearchPage;
