import { Spin, SpinProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Loading(props: SpinProps) {
  return (
    <Spin indicator={antIcon} {...props} />
  );
}

const Block = styled.div`
  margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
`;

export function BlockLoading() {
  return (
    <Block>
      <Loading />
    </Block>
  )
}
