import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import UserPage from './User';
import RegionPage from './Region';
import { useUser } from '../../lib/hooks/use-user';
import MrSystemPage from './MrSystem';
import { Layout, Menu, MenuProps } from 'antd';
import { BarsOutlined, EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
const { Sider, Content } = Layout;

function AdminPage() {
  const { path } = useRouteMatch();
  const location = useLocation();

  const { loading, user: userInfo } = useUser();

  if (!loading && userInfo && !userInfo.roles.includes("Administrator")) {
    return <div>Access denied.</div>
  }

  const selectedKeys = [location.pathname];

  const menuItems: MenuProps['items'] = [
    {
      label: (
        <Link to="/admin/system"><BarsOutlined /> <span>Site</span></Link>
      ),
      key: '/admin/system',
    },
    {
      label: (
        <Link to="/admin/user"><UserOutlined /> <span>User</span></Link>
      ),
      key: '/admin/user',
    },
    {
      label: (
        <Link to="/admin/region"><EnvironmentOutlined /> <span>Region</span></Link>
      ),
      key: '/admin/region',
    },
  ];

  return (
    <>
      <Layout>
        <Sider style={{ background: 'white', paddingTop: 16 }}>
          <Menu
            selectedKeys={selectedKeys}
            mode='inline'
            items={menuItems}
          />
        </Sider>
        <Content style={{ background: 'white' }}>
          <div style={{ padding: "16px" }}>
            <Switch>
              <Route path={`${path}/user`} component={UserPage} />
              <Route path={`${path}/region`} component={RegionPage} />
              <Route path={`${path}/system`} component={MrSystemPage} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default withAuthenticationRequired(AdminPage, {
  onRedirecting: () => <div>loading</div>,
});
