import { Button, Table, TableColumnsType, TablePaginationConfig, Tag } from 'antd';
import { User } from '../../../models/Entities';
import { PaginatedItems, UserQueryParams } from '../../../models/Dtos';
import { FilterValue, SorterResult, TableCurrentDataSource, TableRowSelection } from 'antd/es/table/interface';
import { useTableFilter } from '../../../lib/hooks/use-table-filter';

interface UserListProps {
  paginatedItems?: PaginatedItems<User>,
  isLoading: boolean,
  handleEdit: (vibrationDecoupler: User) => void,
  regionQueryParams: UserQueryParams,
  onFilterChange: (value: UserQueryParams) => void,
  rowSelection: TableRowSelection<User>,
}

function List({
  paginatedItems,
  isLoading,
  handleEdit,
  regionQueryParams,
  onFilterChange,
  rowSelection,
}: UserListProps) {

  const { getColumnSearchProps } = useTableFilter();
  
  const pagination: TablePaginationConfig | undefined = paginatedItems ? {
    pageSize: paginatedItems.pageSize,
    current: paginatedItems.pageIndex,
    total: paginatedItems.count,
    onChange: ((page, pageSize) => {
      onFilterChange({ ...regionQueryParams, pageIndex: page, pageSize });
    }),
  } : undefined;
  
  const columns: TableColumnsType<User> = [
    {
      title: 'Username',
      dataIndex: 'username',
      width: '10%',
      ...getColumnSearchProps('username', 'username'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name', 'name'),
    },
    {
      title: 'Email',
      width: '15%',
      dataIndex: 'email',
      ...getColumnSearchProps('email', 'email'),
    },
    {
      title: 'Department',
      width: '10%',
      dataIndex: 'department',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: (roles: any) => (
        <>
          {roles.map((role: any) => (
            <Tag color="blue" key={role}>
              {role}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'Region',
      dataIndex: 'regionRoles',
      render: (regionRoles: any) => (
        <>
          {regionRoles.map((regionRole: any) => (
            <Tag color="geekblue" key={regionRole.regionId}>
              {regionRole.regionName}: {regionRole.roles.join(',')}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) => <Button size="small" onClick={() => handleEdit(record)}>Edit</Button>,
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
    extra: TableCurrentDataSource<User>
  ) => {
    const params = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }
    onFilterChange(params);
  }

  return (
    <Table<User>
      rowSelection={rowSelection}
      columns={columns}
      rowKey={record => record.userId}
      dataSource={paginatedItems?.items}
      loading={isLoading}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
}

export default List;
