import { Table, TablePaginationConfig } from 'antd';
import { MrSystem } from '../../../models/Entities';
import { ColumnsType } from 'antd/es/table';
import { PaginatedItems, MrSystemQueryParams } from '../../../models/Dtos';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import { useTableFilter } from '../../../lib/hooks/use-table-filter';

interface MrSystemListProps {
  paginatedMrSystems?: PaginatedItems<MrSystem>,
  isLoading: boolean,
  handleEditMrSystem: (mrSystem: MrSystem) => void,
  mrSystemQueryParams: MrSystemQueryParams,
  onFilterChange: (value: MrSystemQueryParams) => void,
}

function List({
  paginatedMrSystems,
  isLoading,
  handleEditMrSystem,
  mrSystemQueryParams,
  onFilterChange,
}: MrSystemListProps) {

  const { getColumnSearchProps } = useTableFilter();
  
  const pagination: TablePaginationConfig | undefined = paginatedMrSystems ? {
    pageSize: paginatedMrSystems.pageSize,
    current: paginatedMrSystems.pageIndex,
    total: paginatedMrSystems.count,
    onChange: ((page, pageSize) => {
      onFilterChange({ ...mrSystemQueryParams, pageIndex: page, pageSize });
    }),
  } : undefined;
  
  const columns: ColumnsType<MrSystem> = [
    {
      title: 'Serial',
      dataIndex: 'mrSystemId',
      width: '10%',
      ...getColumnSearchProps('mrSystemId', 'Serial'),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      ...getColumnSearchProps('country', 'country'),
    },
    {
      title: 'Type',
      width: '15%',
      sorter: true,
      defaultSortOrder: 'descend',
      dataIndex: 'type',
    },
    {
      title: 'Shipment',
      width: '10%',
      sorter: true,
      dataIndex: 'shipment',
    },
    {
      title: 'Site',
      dataIndex: 'site',
      ...getColumnSearchProps('site', 'Site'),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MrSystem> | SorterResult<MrSystem>[],
    extra: TableCurrentDataSource<MrSystem>
  ) => {
    const params = {
      site: filters['site'] ? filters['site'][0].toString() : undefined,
      mrSystemId: filters['mrSystemId'] ? filters['mrSystemId'][0].toString() : undefined,
      country: filters['country'] ? filters['country'][0].toString() : undefined,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }
    onFilterChange(params);
  }

  return (
    <Table<MrSystem>
      columns={columns}
      rowKey={record => record.mrSystemId}
      dataSource={paginatedMrSystems?.items}
      loading={isLoading}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
}

export default List;

