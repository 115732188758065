import useSWR from "swr"
import { SpaSettings } from "../../models/Entities";
import fetcher from "../fetchers/fetcher";

export function useSpaSettings() {
  const { data, error } = useSWR<SpaSettings>(`${process.env.REACT_APP_API_BASE_URL}/settings`, fetcher)

  const loading = !data && !error;

  return {
    data,
    loading,
  }
}
