import { MrSystem } from '../../../models/Entities';
import { MrSystemFormValues, MrSystemQueryParams } from '../../../models/Dtos';
import List from './List';
import { useEntityPage } from '../../../lib/hooks/useEntityPage';


function MrSystemPage() {
  const {
    loading,
    data,
    handleEdit,
    queryParams,
    handleFilterChange,
  } = useEntityPage<MrSystem, MrSystemFormValues, MrSystemQueryParams>('mr-systems', { pageIndex: 1, pageSize: 20 });

  return (
    <>
      <h1>MrSystems</h1>
      <List
        paginatedMrSystems={data}
        isLoading={loading}
        handleEditMrSystem={handleEdit}
        mrSystemQueryParams={queryParams}
        onFilterChange={handleFilterChange}
      />
    </>
  );
}

export default MrSystemPage;

