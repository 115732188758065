import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import SearchPage from './search';
import SystemDetailPage from './detail';
import { BlockLoading } from '../../components/Loading';

function System() {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/search`} component={SearchPage} />
        <Route path={`${path}/:systemId`} component={SystemDetailPage} />
      </Switch>
    </>
  );
}

const SystemPage = withAuthenticationRequired(System, {
  onRedirecting: () => <BlockLoading />,
});

export default SystemPage;
