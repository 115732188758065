import { useEffect, useState } from "react";
import { Input, Form, DatePicker, Space, Button, Popconfirm, message } from "antd";
import { Modal, Radio } from 'antd';
import { RefillingFormValues } from "../../../models/Dtos";
import { AxiosResponse } from "axios";
import { Refilling, RefillingReason } from "../../../models/Entities";

interface FormRefillingProps {
  onCreateRefilling: (values: RefillingFormValues) => Promise<AxiosResponse<Refilling, any>>;
  onUpdateRefilling: (values: RefillingFormValues) => Promise<AxiosResponse<any, any>>;
  onDeleteRefilling: (values: RefillingFormValues) => Promise<AxiosResponse<any, any>>;
  onClose: () => void;
  visible: boolean;
  isNew: boolean;
  initialValues: RefillingFormValues;
}

const FormRefilling = ({
  onCreateRefilling,
  onUpdateRefilling,
  onDeleteRefilling,
  onClose,
  visible,
  isNew = true,
  initialValues = {},
}: FormRefillingProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onFinish = async (values: RefillingFormValues) => {
    setIsSubmitting(true);
    try {
      if (isNew === true) {
        await onCreateRefilling(values);
        message.success('created');
        onClose();
      } else {
        await onUpdateRefilling(values);
        message.success('updated');
        onClose();
      }
    } catch (e) {
      console.log(e);
      message.error('Failed');
    } finally {
      setIsSubmitting(false);
    }
  }

  const onFinishFailed = () => {

  }

  const handleOk = () => {
    form.submit();
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDeleteRefilling(initialValues);
      message.success('deleted');
      onClose();
    } catch (e) {
      console.log(e);
      message.error('Delete failed');
    } finally {
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  return (
    <Modal
      forceRender
      visible={visible}
      title={isNew ? `Fill Helium` : `Edit`}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          disabled={isSubmitting || isDeleting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button
            key="delete"
            disabled={isSubmitting}
            loading={isDeleting}
            danger
          >
            Delete
          </Button>
        </Popconfirm>,
        <Button
          key="save"
          type="primary"
          disabled={isDeleting}
          loading={isSubmitting}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        name="refillingHelium"
        initialValues={{}}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          name="refillingId"
          label="Id"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="mrSystemId"
          label="MR System ID"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="refilledAt"
          label="Date of Refilling"
          rules={[
            { required: true, message: 'Please select date of refilling.' }
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="reason"
          label="Reason for refilling"
          rules={[
            { required: true, message: 'Please answer, thanks.' }
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {Object.keys(RefillingReason).map(key =>
                <Radio value={key} key={key}>
                  <span>{RefillingReason[key]}</span>
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="notes"
          label="Notes"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Charged Helium for Refill"
          rules={[
            { required: true, message: 'Please fill in the charged helium for refill' }
          ]}
        >
          <Input suffix="L" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormRefilling;
