import axios from 'axios';
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

const fetchWithToken = async (url: string, getToken: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) => {
  const token = await getToken();

  const { data } = await axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    },
  });

  return data;
}

export default fetchWithToken;
