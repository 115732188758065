import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr"
import fetchWithToken from "../fetchers/fetch-with-token";
import qs from 'qs'
import { MrSystemLevelDistribution } from "../../models/Dtos";
import { User } from "../../models/Entities";

export function useUser() {
  const { getAccessTokenSilently } = useAuth0();
  const { data, error } = useSWR<User>([`${process.env.REACT_APP_API_BASE_URL}/user`, getAccessTokenSilently], fetchWithToken)

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    user: data,
    loading,
    loggedOut,
  }
}

export function useMrSystemLevelDistribution(params: any) {
  const { getAccessTokenSilently } = useAuth0();
  const queryString = qs.stringify(params, { skipNulls: true });
  const { data, error } = useSWR<MrSystemLevelDistribution>(
    params.regionId ? [`${process.env.REACT_APP_API_BASE_URL}/mr-system-level-distribution?${queryString}`, getAccessTokenSilently] : null,
    fetchWithToken)

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    data,
    loading,
    loggedOut,
  }
}

export function useRefillingsByReason(params: any) {
  const { getAccessTokenSilently } = useAuth0();
  const queryString = qs.stringify(params, { skipNulls: true });
  const { data, error } = useSWR(
    params.regionId ? [`${process.env.REACT_APP_API_BASE_URL}/refillings-by-reason?${queryString}`, getAccessTokenSilently] : null,
    fetchWithToken)

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    data,
    loading,
    loggedOut,
  }
}

export function useRefillingsByMonth(params: any) {
  const { getAccessTokenSilently } = useAuth0();
  const queryString = qs.stringify(params, { skipNulls: true });
  const { data, error } = useSWR(
    params.regionId ? [`${process.env.REACT_APP_API_BASE_URL}/refillings-by-month?${queryString}`, getAccessTokenSilently] : null,
    fetchWithToken)

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    data,
    loading,
    loggedOut,
  }
}
