import { useState } from "react";
import { useEntities } from "./useEntities";

export function useEntityPage<
  EntityType,
  EntityFormValuesType,
  QueryParamsType
>(apiPath: string, defaultQueryParams: QueryParamsType) {
  const [isNew, setIsNew] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<EntityType | {}>({});
  const [queryParams, setQueryParams] =
    useState<QueryParamsType>(defaultQueryParams);
  const { loading, data, mutate, createEntity, updateEntity } = useEntities<
    EntityType,
    EntityFormValuesType
  >(apiPath, queryParams);

  const handleAdd = () => {
    setIsNew(true);
    setInitialValues({});
    setIsFormVisible(true);
  };
  const handleEdit = (model: EntityType) => {
    const initialValues = { ...model };
    setInitialValues(initialValues);
    setIsNew(false);
    setIsFormVisible(true);
  };
  const handleFormClose = () => {
    setIsFormVisible(false);
    mutate();
  };

  const handleFilterChange = (params: QueryParamsType) => {
    setQueryParams(params);
    mutate();
  };

  return {
    data,
    loading,
    isNew,
    isFormVisible,
    initialValues,
    handleAdd,
    handleEdit,
    handleFormClose,
    createEntity,
    updateEntity,
    queryParams,
    setQueryParams,
    handleFilterChange,
    mutate,
  };
}
